<template>
  <div class="navigation-backlink">
    <a v-bind="$attrs"><b-icon icon="arrow-left"/><slot/></a>
  </div>
</template>

<script>
export default {
  name: 'NavigationBacklink',
  inheritAttrs: false
}
</script>

<style lang="scss">
$navigation-backlink-gap: $spacer !default;
$navigation-backlink-icon-gap: $spacer * 0.5 !default;

.navigation-backlink {
  margin-bottom: $navigation-backlink-gap;

  a {
    .bi {
      margin-right: $navigation-backlink-icon-gap;
    }
  }
}
</style>
